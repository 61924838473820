@font-face {
    font-family: 'Unbounded';
    src: url('./fonts/Unbounded.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Inter';
    src: url('./fonts/Inter.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}


@font-face {
    font-family: 'Arimo-M';
    src: url('./fonts/Arimo-Medium.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

body, html {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    display: flex;
    overflow: hidden;
}

body {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    display: flex;
}

.content {
    display: flex;
    display: -webkit-flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    background-image: url('./images/bg.png');
    -webkit-background-size: cover;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    color: white;
}

.title {
    margin-top: 7rem; /* Уменьшаем отступ сверху для поднятия заголовка */
    font-weight: bold;
    font-family: 'Unbounded', sans-serif;
}

.dates {
    margin-top: 0rem; /* Уменьшаем расстояние между заголовком и датами */
    font-weight: bold;
    font-family: 'Unbounded', sans-serif;
}

.stage {
    font-size: 1.3rem; /* Увеличиваем размер шрифта для стадии */
    font-weight: 300;
    font-family: 'Arimo-M';
}

.enter-number {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 50%;
    width: 80%;
    font-size: 1rem; /* base */
    border: none;
    color: #64748b; /* slate-700 */
}

.enter-number input {
    width: 100%;
    padding: 1rem; /* Увеличиваем высоту */
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 13px;
    box-sizing: border-box;
}

.submit-button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5%; /* 9 */
    width: 129px;
    height: 45px;
    background-color: #EA5558;
    border-radius: 20px;
    color: white;
    font-size: 1rem;
    cursor: pointer;
    text-decoration: none;
    outline: none;
    border: none;
    text-align: center;
}
.error-message {
    margin-top: 5%;
    font-size: 2vh;
}